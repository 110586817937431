import { Types as AblyTypes } from 'ably';
import { throttle } from 'lodash';
import { createContext, ReactNode, useContext, useEffect } from 'react';

import { Events } from 'features/common/events/types';
import SocketProvider, { SocketContext } from 'lib/websocket/socket_context';

const notifiers: Record<Events, (type: Events, data: any) => void> = {
  [Events.RELOAD_APPLICATION]: throttle(notify, 300),
  [Events.ORGANIZATION_MODIFIED]: throttle(notify, 3000),
  [Events.RESCHEDULE_COMPLETE]: throttle(notify, 3000),
  [Events.NEW_DISCUSSION_POSTS]: throttle(notify, 30000),
  [Events.ASYNC_JOB]: throttle(notify, 300),
  [Events.INTEGRATION_SYNC_COMPLETED]: throttle(notify, 3000),
  [Events.ITERATION_CHANGE]: throttle(notify, 3000),
  [Events.STORY_POINT_SCHEME_MODIFIED]: throttle(notify, 3000),
};

function notify(type: Events, data: any) {
  dispatchEvent(new CustomEvent(type, { detail: data }));
}

function handleSocketMessage({ data, name }: AblyTypes.Message) {
  notifiers[name as Events](name as Events, data);
}

function SocketMessageHandler() {
  const { generalChannel, organizationWorkspaceChannel } = useContext(SocketContext);

  useEffect(() => {
    generalChannel?.subscribe(handleSocketMessage);
    organizationWorkspaceChannel?.subscribe(handleSocketMessage);

    return () => {
      generalChannel?.unsubscribe(handleSocketMessage);
      organizationWorkspaceChannel?.unsubscribe(handleSocketMessage);
    };
  }, [generalChannel, organizationWorkspaceChannel]);

  return null;
}

// These default values aren't meant to be changed outside of a test environment
export const EventBusContext = createContext({
  subscribe: (name: Events, callback: EventListener) => addEventListener(name, callback),
  unsubscribe: (name: Events, callback: EventListener) => removeEventListener(name, callback),
});

// Component receives messages from the socket, transforms them, and dispatches them.
// Can be adapted to receive messages from other sources.
function EventBus({ children }: { children: ReactNode }) {
  return (
    <>
      <SocketProvider>
        <SocketMessageHandler />
      </SocketProvider>
      {children}
    </>
  );
}

export default EventBus;
