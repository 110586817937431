import { useDispatch, useSelector } from 'react-redux';

import { StoryPointScheme } from 'daos/model_types';
import { StoryPointSchemesDao } from 'daos/story_point_schemes';
import { getCurrentOrganizationId, getCurrentWorkspaceId } from 'features/common/current/selectors';
import { Events, EventScopes } from 'features/common/events/types';
import { useEvents } from 'features/common/events/use_events';
import { awaitRequestFinish } from 'lib/api';
import { replaceStoryPointSchemeOwners } from 'redux/entities/slice';

export const useUpdateStoryPointSchemeOwners = () => {
  const dispatch = useDispatch();
  const organizationId = useSelector(getCurrentOrganizationId);
  const workspaceId = useSelector(getCurrentWorkspaceId);

  const fetchStoryPointSchemes = () => {
    const { uuid } = dispatch(
      StoryPointSchemesDao.fetchAll(
        { organizationId, workspaceId },
        { include: { includeStoryPoints: true, includeSchemeOwners: true } }
      )
    );
    dispatch(
      awaitRequestFinish<StoryPointScheme>(uuid, {
        onSuccess: ({ entities }) => {
          const schemeOwners = Object.values(entities.storyPointSchemeOwners ?? {});
          dispatch(replaceStoryPointSchemeOwners(schemeOwners));
        },
      })
    );
  };

  useEvents({
    callback: fetchStoryPointSchemes,
    event: Events.STORY_POINT_SCHEME_MODIFIED,
    scope: EventScopes.ItemPanel,
  });
};
